<template>
    <v-row>
        <v-col cols="12">
            <v-row no-gutters>
                <v-col cols="12" class="text-center">
                    <v-avatar
                        color="deep-purple darken-4"
                        size="120"
                        class="mb-4"
                    >
                        <v-img :src="$store.state.host+employee.image"></v-img>
                    </v-avatar>
                    <h1 class="subtitle-2">{{ employee.name }}</h1>
                    <p class="caption mb-0">{{ employee.department_name}}, {{ employee.designation_name }}</p>
                    <p class="caption mb-0">{{ employee.phone }}</p>
                </v-col>
            </v-row>

            <v-divider class="mt-4"></v-divider>
            
            <v-row no-gutters>
                <v-col cols="12" class="subtitle-2">Personal Information</v-col>
            </v-row>
            
            <v-divider></v-divider>

            <v-row no-gutters>
                <v-col cols="6">
                    <table>
                        <tbody>
                            <tr><td>Full Name</td><td>:</td><td>{{ employee.name }}</td></tr>
                            <tr><td>Father's Name</td><td>:</td><td>{{ employee.father_name }}</td></tr>
                            <tr><td>Mother's Name</td><td>:</td><td>{{ employee.mother_name }}</td></tr>
                            <tr><td>Present Address</td><td>:</td><td>{{ employee.present_address }}</td></tr>
                            <tr><td>Permanent Address</td><td>:</td><td>{{ employee.permanent_address }}</td></tr>
                            <tr><td>Area</td><td>:</td><td>{{ employee.area_name }}</td></tr>
                            <tr><td>Country</td><td>:</td><td>{{ employee.country_name }}</td></tr>
                            <tr><td>Phone</td><td>:</td><td>{{ employee.phone }}</td></tr>
                        </tbody>
                    </table>
                </v-col>
                <v-col cols="6">
                    <table>
                        <tbody>
                            <tr><td>Email</td><td>:</td><td>{{ employee.email }}</td></tr>
                            <tr><td>Gender</td><td>:</td><td>{{ employee.gender }}</td></tr>
                            <tr><td>Date of Birth</td><td>:</td><td>{{ employee.birth_date }}</td></tr>
                            <tr><td>Blood Group</td><td>:</td><td>{{ employee.blood_group }}</td></tr>
                            <tr><td>Marital Status</td><td>:</td><td>{{ employee.marital_status }}</td></tr>
                            <tr><td>Religion</td><td>:</td><td>{{ employee.religion }}</td></tr>
                            <tr><td>Nationality</td><td>:</td><td>{{ employee.nationality }}</td></tr>
                            <tr><td>NID</td><td>:</td><td>{{ employee.nid }}</td></tr>
                            <tr><td>Details</td><td>:</td><td>{{ employee.details }}</td></tr>
                        </tbody>
                    </table>
                </v-col>

            </v-row>

            <v-divider class="mt-4"></v-divider>

            <v-row no-gutters>
                <v-col cols="8" class="subtitle-2">Employment Information</v-col>
                <v-col cols="4" class="subtitle-2">Salary Information</v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row no-gutters>
                <v-col cols="4">
                    <table>
                        <tbody>
                            <tr><td>Employee Id</td><td>:</td><td>{{ employee.emp_id }}</td></tr>
                            <tr><td>Biometric Id</td><td>:</td><td>{{ employee.biometric_id }}</td></tr>
                            <tr><td>Department</td><td>:</td><td>{{ employee.department_name }}</td></tr>
                            <tr><td>Designation</td><td>:</td><td>{{ employee.designation_name }}</td></tr>
                            <tr><td>Employment Type</td><td>:</td><td>{{ employee.employment_type }}</td></tr>
                            <tr><td>Shift</td><td>:</td><td>{{ employee.shift_name }}</td></tr>
                            <tr><td>Joining Date</td><td>:</td><td>{{ employee.joining_date }}</td></tr>
                            <tr><td>Reporting Boss</td><td>:</td><td>{{ employee.reporting_boss_name }}</td></tr>
                            <tr><td>Confirm Date</td><td>:</td><td>{{ employee.confirmation_date }}</td></tr>
                        </tbody>
                    </table>
                </v-col>
                <v-col cols="4">
                    <table>
                        <tbody>
                            <tr><td>OT Applicable</td><td>:</td><td>{{ employee.ot_allowed | booleanToText }}</td></tr>
                            <tr><td>Allow Absense</td><td>:</td><td>{{ employee.absence_allowed | booleanToText }}</td></tr>
                            <tr><td>Allow Late</td><td>:</td><td>{{ employee.late_allowed | booleanToText }}</td></tr>
                            <tr><td>Bonus Applicable</td><td>:</td><td>{{ employee.bonus_applicable | booleanToText }}</td></tr>
                            <tr><td>TAX Applicable</td><td>:</td><td>{{ employee.tax_applicable | booleanToText }}</td></tr>
                            <tr><td>PF Applicable</td><td>:</td><td>{{ employee.provident_fund_applicable | booleanToText }}</td></tr>
                            <tr><td>Job Responsibilities</td><td>:</td><td>{{ employee.job_responsibilities }}</td></tr>
                        </tbody>
                    </table>
                </v-col>
                <v-col cols="4">
                    <table>
                        <tbody>
                            <tr><td>Gross Salary</td><td>:</td><td>{{ employee.gross_salary }}</td></tr>
                            <tr><td>Basic Salary</td><td>:</td><td>{{ employee.basic_salary }}</td></tr>
                            <tr><td>House Rent</td><td>:</td><td>{{ employee.house_rent_amount }} ({{ employee.house_rent_percent }})</td></tr>
                            <tr><td>Medical Allowance</td><td>:</td><td>{{ employee.medical_allowance_amount }} ({{ employee.medical_allowance_percent }})</td></tr>
                            <tr><td>Mobile Allowance</td><td>:</td><td>{{ employee.mobile_allowance_amount }} ({{ employee.mobile_allowance_percent }})</td></tr>
                            <tr><td>Conveyance Allowance</td><td>:</td><td>{{ employee.conveyance_allowance_amount }} ({{ employee.conveyance_allowance_percent }})</td></tr>
                            <tr><td>Other Allowance</td><td>:</td><td>{{ employee.others_allowance_amount }} ({{ employee.others_allowance_percent }})</td></tr>
                        </tbody>
                    </table>
                </v-col>
            </v-row>

            <v-divider class="mt-4"></v-divider>

            <v-row no-gutters>
                <v-col cols="4" class="subtitle-2">Bank Information</v-col>
                <v-col cols="4" class="subtitle-2">Emergency Contact</v-col>
                <v-col cols="4" class="subtitle-2">Nominee Information</v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row no-gutters>
                <v-col cols="4">
                    <table>
                        <tbody>
                            <tr><td>Bank Name</td><td>:</td><td>{{ employee.bank_name }}</td></tr>
                            <tr><td>Branch Name</td><td>:</td><td>{{ employee.bank_branch_name }}</td></tr>
                            <tr><td>Account Name</td><td>:</td><td>{{ employee.bank_account_name }}</td></tr>
                            <tr><td>Account No.</td><td>:</td><td>{{ employee.bank_account_no }}</td></tr>
                        </tbody>
                    </table>
                </v-col>
                <v-col cols="4">
                    <table>
                        <tbody>
                            <tr><td>Name</td><td>:</td><td>{{ employee.emergency_contact_name }}</td></tr>
                            <tr><td>Address</td><td>:</td><td>{{ employee.emergency_contact_address }}</td></tr>
                            <tr><td>Phone</td><td>:</td><td>{{ employee.emergency_contact_phone }}</td></tr>
                            <tr><td>Email</td><td>:</td><td>{{ employee.emergency_contact_email }}</td></tr>
                        </tbody>
                    </table>
                </v-col>
                <v-col cols="4">
                    <table>
                        <tbody>
                            <tr><td>Name</td><td>:</td><td>{{ employee.nominee_name }}</td></tr>
                            <tr><td>Address</td><td>:</td><td>{{ employee.nominee_address }}</td></tr>
                            <tr><td>Phone</td><td>:</td><td>{{ employee.nominee_phone }}</td></tr>
                            <tr><td>Relation</td><td>:</td><td>{{ employee.nominee_relation }}</td></tr>
                        </tbody>
                    </table>
                </v-col>
            </v-row>

            <v-divider class="mt-4"></v-divider>

            <v-row no-gutters>
                <v-col cols="12" class="subtitle-2">Academic Information</v-col>
            </v-row>
            
            <v-divider></v-divider>

            <v-row no-gutters>
                <v-col cols="12">
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Degree</th>
                                    <th>Institue</th>
                                    <th>Board</th>
                                    <th>Year</th>
                                    <th>Result</th>
                                    <th>Achievements</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(edu, ind) in employee.educations" :key="`edu${ind}`">
                                    <td>{{ edu.degree }}</td>
                                    <td>{{ edu.institute_name }}</td>
                                    <td>{{ edu.board }}</td>
                                    <td>{{ edu.passing_year }}</td>
                                    <td>{{ edu.result }}</td>
                                    <td>{{ edu.achievement }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>

            <v-divider class="mt-4"></v-divider>

            <v-row no-gutters>
                <v-col cols="12" class="subtitle-2">Previous Employments</v-col>
            </v-row>
            
            <v-divider></v-divider>

            <v-row no-gutters>
                <v-col cols="12">
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Company</th>
                                    <th>Department</th>
                                    <th>Post</th>
                                    <th>Salary</th>
                                    <th>Area</th>
                                    <th>Resign Cause</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(pEmp, ind) in employee.previous_employments" :key="`pEmp${ind}`">
                                    <td>{{ pEmp.company_name }}</td>
                                    <td>{{ pEmp.department }}</td>
                                    <td>{{ pEmp.post }}</td>
                                    <td>{{ pEmp.salary }}</td>
                                    <td>{{ pEmp.area }}</td>
                                    <td>{{ pEmp.resign_cause }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import utility from '../mixins/utility.mixin';
export default {
    mixins: [utility],

    props: ['employee'],
}
</script>

<style lang="scss" scoped>
    table {
        width: 100%;
        font-size: 12px;

        td {
            vertical-align: top!important;

            &:first-child {
                font-weight: bold;
                width: 150px;
            }

            &:nth-child(2) {
                width: 20px;
            }
        }
    }

    .v-data-table {
        td {
            font-size: 13px!important;
            font-weight: normal!important;
        }
    }
</style>